import { Card, Grid } from '@krakentech/coral';
import React, { FC } from 'react';

import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import { Link } from '@/components/shared/Link';
import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';
import { BlogpostItemFragment } from '@/services/graphql-storyblok';

type Props = {
	excerpt?: string;
	href?: string;
	imageSrc?: string;
	name?: string;
};

const BlogCard: FC<Props> = ({ href, excerpt, imageSrc, name }) => (
	<Card
		padding="small"
		image={{ src: imageSrc ? optimizeStoryblokImage({ src: imageSrc }) : null }}
		fullHeight
	>
		<div className="flex flex-col">
			<p className="mb-6 text-lg font-bold md:min-h-[128px] md:text-2xl lg:min-h-[100px] xl:min-h-[96px]">
				{name}
			</p>
			<p className="min-h-[150px] md:min-h-[192px] lg:min-h-[150px]">
				{excerpt}
			</p>
			{href && (
				<div className="mb-4 mt-2">
					<Link isButton href={href} fullWidth color="secondary">
						もっと読む
					</Link>
				</div>
			)}
		</div>
	</Card>
);

export type HomePageSectionProps = {
	featuredBlogStories: BlogpostItemFragment[];
};

export const HomePageSectionBlog: FC<HomePageSectionProps> = ({
	featuredBlogStories,
}) => (
	<section>
		<div className="flex flex-col space-y-12 md:pb-14" id="blog-section">
			<HomePageSectionHeading
				headingText="オクトパス、電気のこともっと知ってみませんか？"
				subheadingText="ブログで我らが8次元の世界を覗いてみましょう"
			/>
		</div>
		<div className="mx-auto flex w-full">
			<Grid
				templateColumns="repeat(1,1fr)"
				templateRows="repeat(3,1fr)"
				md={{
					templateColumns: 'repeat(3,1fr)',
					templateRows: 'repeat(1,1fr)',
				}}
				gap="sm"
			>
				{featuredBlogStories.map(
					({ content, full_slug, name }, index: number) => (
						<Grid.Item key={index}>
							<BlogCard
								excerpt={content?.excerpt ?? undefined}
								href={full_slug ?? undefined}
								imageSrc={content?.banner_image_desktop?.filename ?? undefined}
								name={name ?? undefined}
							/>
						</Grid.Item>
					)
				)}
			</Grid>
		</div>
	</section>
);
