import { SkipToContent } from '@krakentech/coral';
import * as Sentry from '@sentry/nextjs';
import { GetServerSideProps } from 'next';
import { FC } from 'react';

import { AbTestVariantIdContext } from '@/components/contexts/abTestVariantId';
import { DomesticRewardContext } from '@/components/contexts/domesticReward';
import { StoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import {
	StoryBlokFuelMixContext,
	StoryBlokFuelMixContextProp,
} from '@/components/contexts/storyblokFuelMix';
import { HomePage } from '@/components/pages/home/HomePage';
import {
	BannerItemFragment,
	BlogpostItemFragment,
} from '@/services/graphql-storyblok';
import { AB_TEST_SHORTCUT_HERO, getABTestVariantId } from '@/utils/ab-testing';
import { getActiveDomesticSignupRewardScheme } from '@/utils/api/getActiveDomesticSignupRewardScheme';
import { getBannerStories } from '@/utils/api/getBannerStories';
import { getFeaturedBlogStories } from '@/utils/api/getFeaturedBlogStories';
import { getStoryRichText } from '@/utils/api/getStoryRichText';
import { getI18nProps } from '@/utils/i18n/getI18nProps';
import { logError } from '@/utils/logger';

type HomeProps = {
	activeDomesticSignupRewardSchemeCode?: string;
	bannerStories: BannerItemFragment[];
	featuredBlogStories: BlogpostItemFragment[];
	fuelMixStories: StoryBlokFuelMixContextProp;
	serversideAbTestVariantId?: string | null;
};

const Home: FC<HomeProps> = ({
	activeDomesticSignupRewardSchemeCode,
	bannerStories = [],
	featuredBlogStories,
	fuelMixStories,
	serversideAbTestVariantId,
}) => {
	return (
		<DomesticRewardContext.Provider
			value={{ activeDomesticSignupRewardSchemeCode }}
		>
			<StoryBlokFuelMixContext.Provider value={fuelMixStories}>
				<AbTestVariantIdContext.Provider
					value={{ variantId: serversideAbTestVariantId }}
				>
					<SkipToContent
						id="maincontent"
						label="メインコンテンツまでスキップ"
					/>
					<StoryblokBannersContext.Provider value={{ bannerStories }}>
						{featuredBlogStories && (
							<HomePage featuredBlogStories={featuredBlogStories} />
						)}
					</StoryblokBannersContext.Provider>
				</AbTestVariantIdContext.Provider>
			</StoryBlokFuelMixContext.Provider>
		</DomesticRewardContext.Provider>
	);
};

const getStoryblokGreenFuelMix =
	async (): Promise<StoryBlokFuelMixContextProp> => {
		try {
			return {
				fuelMixGreen: await getStoryRichText('pages/fuel-mix-green'),
				fuelMixStandard: null,
				fuelMixSimple: await getStoryRichText('pages/fuel-mix-simple'),
			};
		} catch (error) {
			Sentry.captureMessage('Error fetching Storyblok fuel-mix-green data.', {
				extra: { error },
			});
			throw Error(`Error fetching Storyblok data: ${error}`);
		}
	};

export const getServerSideProps: GetServerSideProps<HomeProps> = async (
	context
) => {
	const [activeDomesticSignupRewardSchemeCode, bannerStories, i18nProps] =
		await Promise.all([
			getActiveDomesticSignupRewardScheme()
				.then((data) => data?.activeDomesticSignupRewardScheme?.code ?? '')
				.catch((error) => {
					logError('Error calling getActiveDomesticSignupRewardScheme', error);
					return '';
				}),
			getBannerStories().catch((error) => {
				logError('Error calling getBannerStories', error);
				return [];
			}),
			getI18nProps(['common', 'account', 'obj']).catch((error) => {
				logError('Error calling getI18nProps', error);
				return {};
			}),
		]);

	const featuredBlogStories = await getFeaturedBlogStories(
		'data/featured-blog-landing-page'
	);
	const storyblokFuelMixGreenStory = await getStoryblokGreenFuelMix();

	const serversideAbTestVariantId = getABTestVariantId(
		AB_TEST_SHORTCUT_HERO,
		context
	);

	return {
		props: {
			fuelMixStories: storyblokFuelMixGreenStory,
			activeDomesticSignupRewardSchemeCode,
			bannerStories,
			serversideAbTestVariantId,
			...i18nProps,
			featuredBlogStories,
		},
	};
};

export default Home;
