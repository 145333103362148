import { Button, Card } from '@krakentech/coral';
import { FC } from 'react';

import { AreaPin } from '@/components/svgs/AreaPin';
import {
	SupportedArea,
	SupportedAreas,
} from '@/utils/constants/industry/gridOperator';
import { sendHomepagePriceCheckAreaAnalytics } from '@/utils/googleAnalytics';

const AreaSelectSection: FC<{
	selectedArea: SupportedArea | '';
	setSelectedArea: (area: SupportedArea) => void;
}> = ({ setSelectedArea, selectedArea }) => {
	return (
		<div className="w-full xs:px-4 sm:px-0">
			<Card theme="dark" borderless fullHeight>
				<div className="mb-12 flex items-center space-x-4">
					<AreaPin />
					<p className="text-lg md:text-xl">お住まいのエリアを選んでください</p>
				</div>
				<div className="grid grid-cols-3 gap-4">
					{SupportedAreas.map((area) => (
						<Button
							variant={selectedArea === area ? 'contained' : 'outlined'}
							color="secondary"
							size="small"
							key={area}
							onClick={() => {
								sendHomepagePriceCheckAreaAnalytics(area);
								setSelectedArea(area);
							}}
						>
							<div className="py-2 text-sm md:py-0 md:text-base">
								{area}
								<span className="hidden md:block">エリア</span>
							</div>
						</Button>
					))}
				</div>
			</Card>
		</div>
	);
};

export { AreaSelectSection };
