import { featureFlags } from '@/features/featureFlags';
import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import {
	DefaultExcludeProductParams,
	ProductFilterParamType,
	ProductParamsGreenOctopus,
	ProductParamsSimpleAndGreenOctopus,
} from '@/queriesAndMutations/quote';
import { SupplyPointStatus } from '@/services/typed-graphql-sdk';

export const useProductParamsQuickQuote = (
	status?: SupplyPointStatus
): ProductFilterParamType => {
	const affiliateSalesChannel = useReadSessionStorage('affiliateSalesChannel');
	const isPriceComparisonChannel = affiliateSalesChannel === 'PRICE_COMPARISON';
	const isB2B2CChannel = affiliateSalesChannel === 'B2B2C';
	const isMoveOutPendingStatusUser = status === 'MOVE_OUT_PENDING';

	const productParamsQuickQuote = featureFlags.NEXT_PUBLIC_QUICK_QUOTE_OBJ
		? isPriceComparisonChannel || isB2B2CChannel
			? ProductParamsGreenOctopus
			: ProductParamsSimpleAndGreenOctopus
		: ProductParamsGreenOctopus;

	// No offer Simple Octopus to the user already move-out and try to move-in again
	if (isMoveOutPendingStatusUser) {
		return DefaultExcludeProductParams;
	}

	return productParamsQuickQuote;
};
